<template>
  <section  class='w-100 register'>
    <section>
      <section class='mb-5 mt-3'>
        <b-form-input
          v-if='showPhoneField'
          id='phone'
          class='input_code'
          :state='validCode'
          v-model='phoneUpdate'
          v-mask="'(##) #####-####'"
          aria-describedby='input-live-help input-live-feedback'
          placeholder='Digite seu telefone celular'
          trim>
        </b-form-input>
        <b-form-invalid-feedback :state='validCode' class='w-100 position-absolute text-left' id='phone'>
          Código de segurança inválido
        </b-form-invalid-feedback>
      </section>

      <section>
        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class=' w-100'
        >
          <base-button

            :disabled='!validate()'
            @click='updatePhone()'
            class='py-2 w-100'>
            <span class='text_btn'>Atualizar telefone</span>
          </base-button>
        </b-overlay>
      </section>
    </section>
  </section>
</template>

<script>

import { mask } from 'vue-the-mask'
import { sendSMS } from '../../services/SMS'
import { phoneValidator } from '../../utils/phoneValidator'
import { mapActions } from 'vuex'

export default {
  namer: 'ChangePhone',
  directives: { mask },
  components: {},
  props: {
    username: { type: String, default: null },
    phone: { type: String, default: null }
  },
  computed: {
    validCode() {
      return phoneValidator(this.phoneUpdate.replace(/[^\d]/g, ''))
    }
  },
  data() {
    return {
      phoneUpdate: '',
      loading: false,
      showPhoneField: false,
    }
  },
  validations() {
    return {}
  },
  created() {
    this.$store.dispatch('access/updateError', '')
    this.$store.dispatch('access/updateMessage', '')
  },
  mounted() {
    this.phoneUpdate = this.phone
    this.showPhoneField = true
  },
  methods: {
    ...mapActions('user', {
      handleChangePhone: 'handleChangePhone'
    }),
    validate() {
      return phoneValidator(this.phoneUpdate.replace(/[^\d]/g, ''))
    },
    async updatePhone() {
      try {
        this.loading = true
        const body = {
          username: this.username,
          phone: this.phoneUpdate.replace(/[^\d]/g, '')
        }
        const { user } = await this.handleChangePhone(body)
        this.loading = false

        this.$emit('new-phone', user.profile.phone)
      } catch (e) {

      }
    }
  }
}
</script>

<style scoped>
.input_code {
  height: 45px;
  border-radius: 8px;
  font-size: 20px;
  margin-right: 10px;
  padding-left: 10px!important;
  border: 1.5px solid #cccccc!important;
}
.register{
  min-height: 150px;
}
</style>
