import axios from 'axios'
import { decrypt, encrypt } from '@/utils/EncryptDecryptData'
const baseUrl = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 180000 /* 3 minutes */,
  headers: {},

})
export const sendSMS = async (body) => {
  try {
    return new Promise((resolve, reject) => {
      baseUrl.post(`/service/sendSMS`, body)
        .then((data) => {
          const {data: response} = data
          data.data = decrypt(response)
          return resolve(data)
        })
        .catch((error) => {
          return resolve(error.response)
        })
    })
  } catch (error) {
    console.log(error)
  }
}
export const validateSecurityCode = async (body) => {
  try {
    return new Promise((resolve, reject) => {
      baseUrl.post(`/service/validateSecurityCode`, body)
        .then((data) => {
          const {data: response} = data
          data.data = decrypt(response)
          return resolve(data)
        })
        .catch((error) => {
          return resolve(error.response)
        })
    })
  } catch (error) {
    console.log(error)
  }
}
