<template>
  <b-overlay
    :show='loading'
    rounded='sm'
    spinner-variant='primary'
    class='d-inline-flex w-100'
  >
    <b-card
      class='m-3'
      style='width: 97%'
      border-variant='primary'
      header='Atualização de telefone'
      header-bg-variant='primary'
      header-text-variant='white'
      align='center'
    >
      <div v-if='!linkUpdatePhone' class='my-2'>
        <h4>Link não encontrado, verique o link enviado por e-mail!</h4>
      </div>

      <div v-else-if='linkUpdatePhone && !linkUpdatePhone.usedLink'>
        <change-phone
          :username='username'
          :phone='phone'
          @new-phone='setNewPhone'
        />
      </div>
      <div v-else-if='linkUpdatePhone && linkUpdatePhone.usedLink' class='my-2'>
        <h4 v-html='message'> </h4>
      </div>

    </b-card>
  </b-overlay>
</template>

<script>
import ChangePhone from '@/views/Access/ChangePhone'
import { mapActions } from 'vuex'

export default {
  name: 'UserPreferences',
  components: {
    ChangePhone
  },
  data() {
    return {
      message: 'Esse link para atualizar telefone já foi usado!',
      username: '',
      params: {},
      phone: '',
      loading: true,
      linkUpdatePhone: null
    }
  },
  async mounted() {
    this.params = this.$route.query
    await this.getLinkUpdatePhone()
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    }
  },
  methods: {
    ...mapActions('linkUpdatePhone', {
      handleGetLinkUpdatePhone: 'handleGetLinkUpdatePhone',
      handleUpdateLinkUpdatePhone: 'handleUpdateLinkUpdatePhone'
    }),
    async setNewPhone() {
      try {
        const { status, data } = await this.handleUpdateLinkUpdatePhone(this.linkUpdatePhone._id)
        let message = data.error
        let variant = 'danger'
        if(status === 200){
          message = 'Telefone atualizado com sucesso!';
          variant = 'success';
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 10000
        })
        await this.getLinkUpdatePhone()
        this.message = `Telefone atualizado com sucesso, <a href='/login'>fazer login</a>`
      } catch (e) {

      }
    },
    async getLinkUpdatePhone() {
      try {
        this.loading = true
        const { linkUpdatePhone } = await this.handleGetLinkUpdatePhone(this.params)

        if (linkUpdatePhone) {
          this.linkUpdatePhone = linkUpdatePhone
          this.phone = this.linkUpdatePhone.phone
          this.username = this.linkUpdatePhone.username
        }
      } catch (e) {

      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang='scss'>
</style>
